<template>
  <div>
    <DashboardTemplate>
      <el-tabs v-model="activeTab" type="border-card">
        <el-tab-pane label="Accounting" name="accounting">
          <Accounting :activeTab="activeTab"/>
        </el-tab-pane>
        <el-tab-pane label="Inventory" name="inventory">
          <InventoryBalance :activeTab="activeTab" :userId="user._id" />
        </el-tab-pane>
        <el-tab-pane label="Sale Order" name="saleOrder">
          <SaleOrder :activeTab="activeTab" />
        </el-tab-pane>
        <el-tab-pane
          label="Sold quantity"
          name="soldQuantity"
          :disabled="room.current === 'Sale Order'"
        >
          <SoldQuantity :priceData="priceData" :saleData="saleData" />
        </el-tab-pane>
        <el-tab-pane label="Invest" name="invest" :disabled="room.current !== 'Investment'">
          <Invest v-if="room.current == `Investment`" :activeTab="activeTab" />
        </el-tab-pane>
        <el-tab-pane
          label="World's demand"
          name="worldDemand"
          :disabled="room.current !== `World's demand` && room.current !== `Result`"
        >
          <h2>Ranking</h2>
          <Result :data="resultData" class="mg-b-2" />
          <h2>World’s Demand</h2>
          <WorldDemand :data="worldData" />
          <h2>Sold Quantity</h2>
          <SoldQuantity :priceData="priceData" :saleData="saleData" />
          <div
            v-if="room.current == `World's demand` && room.mode == 'single'"
            class="text-right mg-t-3"
          >
            <el-row justify="end" type="flex">
              <el-col :span="4">
                <el-button
                  class="mg-y-5 w-100 btn-primary"
                  type="primary"
                  @click="[confirmModal = true,round = room.currentRound + 1 ]"
                  round
                >{{ room.currentRound < 6 ? "Next round" : "End game" }}</el-button>
              </el-col>
            </el-row>
          </div>
        </el-tab-pane>
      </el-tabs>
      <el-dialog
        :visible.sync="modalCard"
        @close="setDelay()"
        width="30%"
        class="animated animate-spin"
      >
        <div class="modalEventCard" :class="delayCss ? 'block' :'' ">
          <div v-if="delayCss" class="animated fadeInUp">
            <h3
              class="text-center"
              :class="card.cardId!=0?(checkActionEvent(card.cardId))?'color-success':'color-danger':''"
            >Business Event</h3>
            <p>
              {{card.nameCard }}
              <span v-if="checkActionEvent(card.cardId)" class="mg-less">{{card.action}}</span>
            </p>
          </div>
          <div class="text-right">
            <span slot="footer" class="dialog-footer">
              <el-button type="primary" @click="modalCard = false">Ok</el-button>
            </span>
          </div>
        </div>
      </el-dialog>

      <el-dialog
        :visible.sync="dataRoundModal"
        width="55%"
        :before-close="handleClose"
        @closed="$store.commit('SET_DATA_ROUND_MODAL',false)"
        class="close-header"
      >
        <div>
          <h1 class="mg-t-0 pd-x-4 text-center">รอบที่ {{room.currentRound}}</h1>
          <h2 class="mg-t-5 pd-x-4 text-center">Economic Indicators</h2>
          <el-divider class="mg-y-5"></el-divider>
          <el-row type="flex" justify="center" class="pd-b-4">
            <el-col :span="12" class="indicatorsBdr">
              <div class="pd-x-4">
                <h2>Amfreland</h2>
                <p v-if="dataRound[room.currentRound - 1].id === 1" class="font-18">
                  GDP :
                  <span>${{dataRound[room.currentRound - 1].amfreland.gdp | commaNoDot}}M</span>
                </p>
                <p v-else class="font-18">
                  GDP Growth:
                  <span>{{dataRound[room.currentRound - 1].amfreland.gdp}}%</span>
                </p>
                <p class="font-18">
                  รายได้เฉลี่ย:
                  <span>${{dataRound[room.currentRound - 1].amfreland.incomeAvg | commaNoDot}}</span> / เดือน
                </p>
                <p class="font-18">
                  ประชากรที่อยู่ในเขตเมือง:
                  <span>${{dataRound[room.currentRound - 1].amfreland.population | commaNoDot}}%</span>
                </p>
                <p v-if="dataRound[room.currentRound - 1].id === 1" class="font-18">
                  สัดส่วน Upper/ Middle/ Lower:
                  <span>{{dataRound[room.currentRound - 1].amfreland.proportion.upper | commaNoDot}}/</span>
                  <span>{{dataRound[room.currentRound - 1].amfreland.proportion.middle | commaNoDot}}/</span>
                  <span>{{dataRound[room.currentRound - 1].amfreland.proportion.lower | commaNoDot}}</span>
                </p>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="pd-x-4 text-right">
                <h2>Belovia</h2>
                <p v-if="dataRound[room.currentRound - 1].id === 1" class="font-18">
                  GDP :
                  <span>${{dataRound[room.currentRound - 1].belovia.gdp | commaNoDot}}M</span>
                </p>
                <p v-else class="font-18">
                  GDP Growth:
                  <span>{{dataRound[room.currentRound - 1].belovia.gdp}}%</span>
                </p>
                <p class="font-18">
                  รายได้เฉลี่ย:
                  <span>${{dataRound[room.currentRound - 1].belovia.incomeAvg | commaNoDot}}</span> / เดือน
                </p>
                <p class="font-18">
                  ประชากรที่อยู่ในเขตเมือง:
                  <span>${{dataRound[room.currentRound - 1].belovia.population | commaNoDot}}%</span>
                </p>
                <p v-if="dataRound[room.currentRound - 1].id === 1" class="font-18">
                  สัดส่วน Upper/ Middle/ Lower:
                  <span>{{dataRound[room.currentRound - 1].belovia.proportion.upper | commaNoDot}}/</span>
                  <span>{{dataRound[room.currentRound - 1].belovia.proportion.middle | commaNoDot}}/</span>
                  <span>{{dataRound[room.currentRound - 1].belovia.proportion.lower | commaNoDot}}</span>
                </p>
              </div>
            </el-col>
          </el-row>
          <div class="text-center">
            <span
              v-if="fxRate.length > 0"
              class="btn-FxRate font-18"
            >FX Rate : $1 = ฿{{fxRate[room.currentRound - 1].currentRate}}</span>
          </div>
        </div>
      </el-dialog>

      <el-dialog :visible.sync="rateEndGameModal" width="55%" :before-close="handleClose">
        <div>
          <h1 class="mg-t-5 pd-x-4">End of Round FX</h1>
          <el-divider class="mg-y-5"></el-divider>
          <div class="text-center">
            <div
              class="btn-FxRate is-flex js-center font-30 pd-1 mg-x-4 mg-t-2"
            >FX Rate : $1 = ฿30.5</div>
          </div>
        </div>
      </el-dialog>

      <el-dialog :visible.sync="confirmModal" width="30%">
        <h3 class="text-center" v-if="round <= 6">เริ่มต้นรอบที่ {{round}}</h3>
        <h3 class="text-center" v-else>End game</h3>
        <span slot="footer" class="dialog-footer">
          <el-button @click="confirmModal = false">Cancle</el-button>
          <el-button
            type="primary"
            @click="round <= 6 ? nextRound(): endGame()"
            :loading="loadingConfirm"
          >Confirm</el-button>
        </span>
      </el-dialog>
    </DashboardTemplate>
  </div>
</template>

<script>
import Result from "@/components/Result/Result";
import DashboardTemplate from "@/template/DashboardTemplate";
import SaleOrder from "@/components/saleOrder/SaleOrder";
import InventoryBalance from "@/components/inventory/InventoryBalance";
import SoldQuantity from "@/components/soldQuantity/SoldQuantity";
import Accounting from "@/components/accounting/Accounting";
import Invest from "@/components/invest/Invest";
import WorldDemand from "@/components/WorldDemand/WorldDemand";
import { HTTP } from "@/service/axios";
export default {
  components: {
    DashboardTemplate,
    SaleOrder,
    InventoryBalance,
    SoldQuantity,
    Accounting,
    Invest,
    WorldDemand,
    Result
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    room() {
      return this.$store.state.room;
    },
    groupAllData() {
      return this.$store.state.groupAllData;
    },
    accounts() {
      if (this.groupAllData === null) {
        return "";
      }
      let filter = this.groupAllData.accounts.filter(
        filter => filter.round === this.room.currentRound
      );
      if (filter.length > 0) {
        return filter[0];
      } else {
        return "";
      }
    },
    fxRate() {
      return this.$store.state.fxRate;
    },
    openModalDataRound() {
      return this.$store.state.openModalDataRound;
    }
  },
  watch: {
    async activeTab() {
      if (this.activeTab === "soldQuantity") {
        await this.fetchGroup();
        await this.fetchDataSoldQ();
      } else if (this.activeTab === "worldDemand") {
        await this.fetchGroup();
        await this.fetchDataSoldQ();
        this.fetchDemand();
        this.fetchResult();
      }
    },
    openModalDataRound() {
      this.dataRoundModal = this.openModalDataRound;
    }
  },
  data() {
    return {
      loadingConfirm: false,
      round: 0,
      confirmModal: false,
      delayCss: false,
      modalCard: false,
      card: "",
      activeTab: "",
      saleData: [],
      worldData: [],
      priceData: [],
      resultData: [],
      renderSoldQ: false,
      rateEndGameModal: false,
      dataRoundModal: false,
      dataRound: [
        {
          id: 1,
          amfreland: {
            gdp: 200000,
            incomeAvg: 1500,
            population: 70,
            proportion: {
              upper: 40,
              middle: 50,
              lower: 10
            }
          },
          belovia: {
            gdp: 71000,
            incomeAvg: 200,
            population: 10,
            proportion: {
              upper: 10,
              middle: 10,
              lower: 80
            }
          }
        },
        {
          id: 2,
          amfreland: {
            gdp: 3,
            incomeAvg: 1700,
            population: 72
          },
          belovia: {
            gdp: 7,
            incomeAvg: 300,
            population: 15
          }
        },
        {
          id: 3,
          amfreland: {
            gdp: 2.5,
            incomeAvg: 1700,
            population: 72
          },
          belovia: {
            gdp: 8,
            incomeAvg: 500,
            population: 20
          }
        },
        {
          id: 4,
          amfreland: {
            gdp: 2,
            incomeAvg: 1710,
            population: 72
          },
          belovia: {
            gdp: 9,
            incomeAvg: 600,
            population: 30
          }
        },
        {
          id: 5,
          amfreland: {
            gdp: 1,
            incomeAvg: 1640,
            population: 72
          },
          belovia: {
            gdp: 10,
            incomeAvg: 680,
            population: 35
          }
        },
        {
          id: 6,
          amfreland: {
            gdp: -0.3,
            incomeAvg: 1440,
            population: 72
          },
          belovia: {
            gdp: 12,
            incomeAvg: 720,
            population: 40
          }
        }
      ]
    };
  },
  async mounted() {
    this.fetchGroup();
    this.fetchFxRate();
    if (this.room.current == "Sold Q Announcement") {
      this.fetchDataSoldQ();
    }
    await this.fetchRoom();
    this.onRoom();
    this.onStartRound();
    
    if ((this.room.mode == 'single' && this.room.current != 'Financial product') || this.room.mode != 'single') {
        this.forcePage(this.room.current);
    }
    
    this.onEventCard();
    this.onGetAcc();
    this.dataRoundModal = this.openModalDataRound;
  },
  methods: {
    handleClose(done) {
      this.$confirm(
        "คุณแน่ใจหรือไม่ที่จะปิดหน้าต่างนี้ <br>หากปิดแล้วจะไม่สามารถดูข้อมูลได้อีก?",
        { dangerouslyUseHTMLString: true, center: true }
      )
        .then(_ => {
          done();
        })
        .catch(_ => {});
    },
    async endGame() {
      this.loadingConfirm = true;
      this.room.current = "End Game";
      await this.$store.dispatch("updateCurrent", this.room);
      if(this.user != null && this.user.userLevel == 80 ){
        await this.$socket.client.emit("getRoom", {
          roomNumber: this.room.roomNumber
        });
      }
      await HTTP.delete("/end/" + this.room._id).then(res => {

        if (res.data.success) {
          this.confirmModal = false;
          if (this.room.mode == "single") {
            this.$router.push("/select-mode").catch(err => {});
          } else {
            this.$router.push("/join").catch(err => {});
          }
          this.loadingConfirm = false;
          if(this.user != null && this.user.userLevel != 80 ){
            this.$notify({
              title: 'Room #'+this.room.roomNumber+' has been end.',
              message: 'เกมส์ #'+this.room.roomNumber+' ได้สิ้นสุดแล้ว',
              type: 'success'
            });
          }
        }
      });
    },
    async nextRound() {
      this.loadingConfirm = true;
      this.room.current = "Sale Order";
      (this.room.currentRound = this.room.currentRound + 1),
        await this.$store.dispatch("updateCurrent", this.room);
      await this.$socket.client.emit("getRoom", {
        roomNumber: this.room.roomNumber
      });
      this.updateDateRound(this.room.currentRound)
      setTimeout(() => {
        this.$router.push("/random-payment").catch(err => {});
        this.loadingConfirm = false;
      }, 200);
    },
    setDelay() {
      setTimeout(() => {
        this.delayCss = false;
      }, 5000);
    },
    checkActionEvent(id) {
      if (id == 10 || id == 11 || id == 12 || id == 13 || id == 14) {
        return true;
      } else {
        return false;
      }
    },
    onGetAcc() {
      this.$socket.client.on("resGetAcc", async res => {
        if (
          res.success &&
          res.obj.roomId === this.room._id &&
          res.obj.groupId === this.user._id
        ) {
          this.$store.commit("SET_GROUP_ALL_DATA", res.obj);
          this.$store.commit("SET_RENDER_ANIMATE", {
            key: "paymentTerm",
            value: false
          });

          setTimeout(() => {
            this.$store.commit("SET_RENDER_ANIMATE", {
              key: "paymentTerm",
              value: true
            });
          }, 500);
        }
      });
    },
    fetchPriceInventory() {
      let obj = {
        round: this.room.currentRound
      };

      this.$store.dispatch("FETCH_PRICE_PRODUCT", obj);
    },
    onEventCard() {
      this.$socket.client.on("resGetCardsId", res => {
        if (res.success && res.roomId == this.room._id) {
          let filterData = res.obj.filter(search => {
            return search.groupId == this.user._id;
          });
          setTimeout(() => {
            this.delayCss = true;
          }, 1200);

          if (filterData.length > 0) {
            if (this.room.mode != "single") {
              this.card = filterData[0];
              this.modalCard = true;
            }
          }
          this.fetchGroupAllData();
        }
      });
    },
    fetchFxRate() {
      let obj = {
        round: this.room.currentRound
      };
      this.$store.dispatch("FETCH_FX_RATE", obj);
    },
    fetchResult() {
      HTTP.get("/summary/" + this.room._id + "/" + this.room.currentRound).then(
        res => {

          if (res.data.success) {
            this.resultData = res.data.obj;
          }
        }
      );
    },
    setActiveTab(tab){
      this.activeTab = tab
    },
    forcePage(data) {

      if (data == "Sale Order") {
        this.activeTab = "saleOrder";
      } else if (data == "Sold Q Announcement") {
        this.activeTab = "soldQuantity";
      } else if (data == "Financial product") {
        this.activeTab = "accounting";
      } else if (data == "Investment") {
        this.activeTab = "invest";
      } else if (data == "World's demand") {
        this.activeTab = "worldDemand";
      } else if (data == "Result") {
        this.activeTab = "resultGame";
      } else if (data == "End Game") {
        this.$router.push("/select-mode").catch(err => {});
        if(this.room != null){
          this.$notify({
            title: "Room #" + this.room.roomNumber + " has been end.",
            message: "เกมส์ #" + this.room.roomNumber + " ได้สิ้นสุดแล้ว",
            type: "success"
          });
          this.$store.commit("SET_ROOM", null);
        }
      } else if (data == "Destroy Game") {
        this.$router.push("/select-mode").catch(err => {});
        if(this.room != null){
          this.$notify.error({
            title: "Room #" + this.room.roomNumber + " was destroyed.",
            message:
              "ขออภัย เกมส์ #" + this.room.roomNumber + " ได้ถูกยกเลิกแล้ว",
            type: "error"
          });
          this.$store.commit("SET_ROOM", null);
        }
        
      }
    },
    onRoom() {
      this.$socket.client.on("resGetRoom", async res => {
        if (this.room.roomNumber == res.obj.roomNumber) {
          this.fetchFxRate();

          this.forcePage(res.obj.current);
          if(this.room.current != 'End Game' && this.room.current != 'Destroy Game' && this.room != null){
            this.$store.commit("SET_ROOM", res.obj);

          }
          if ( res.obj.currentRound === 6 && res.obj.current === "World's demand" ) {
            this.rateEndGameModal = true;
          }
        }
      });
    },
    onStartRound() {
      this.$socket.client.on("resGate", async res => {

        if (this.room.roomNumber == res.roomNumber) {
          this.updateDateRound(res.currentRound)
        }
      });
    },
    updateDateRound(round){
          this.refundPrinciple(round);
          this.updateInvestment(round);
          this.fetchPriceInventory();
          this.setFinanceProducts();
          if (this.room.mode !== "single") {
            this.$store.commit("SET_DATA_ROUND_MODAL", true);
          }
    },
    setFinanceProducts() {
      let obj = {
        credit: 0,
        creditCheck: false,
        insurance: false,
        forward: false,
        insuranceAmount: 0
      };
      this.$store.commit("SET_FINAN_PRODUCTS", obj);
    },
    async fetchRoom() {
      let obj = {
        number: this.room.roomNumber
      };
      await this.$store.dispatch("FETCH_GROUPS", obj);
      await this.$store.dispatch("FETCH_SEARCH_ROOM", obj);
    },
    async fetchGroup() {
      await HTTP.get("/groups/" + this.room.roomNumber).then(res => {
 
        if (res.data.success) {
          let data = {
            label: "groups",
            value: res.data.obj.groups
          };
          this.$store.commit("SET_ROOMDATA", data);
        }
      });
    },
    fetchDemand() {
      this.worldData = [];
      HTTP.get(
        "/world/demand/" + this.room.roomNumber + "/" + this.room.currentRound
      ).then(res => {

        if (res.data.success) {
          for (var i = 0; i < 2; i++) {
            let data = {
              name: i == 0 ? "Amfreland" : "Belovia",
              whiteRice:
                i == 0
                  ? res.data.obj.amfreland.whiteRice
                  : res.data.obj.belovia.whiteRice,
              jasmineRice:
                i == 0
                  ? res.data.obj.amfreland.jasmineRice
                  : res.data.obj.belovia.jasmineRice,
              whiteRiceCan:
                i == 0
                  ? res.data.obj.amfreland.whiteRiceCan
                  : res.data.obj.belovia.whiteRiceCan,
              jasmineRiceCan:
                i == 0
                  ? res.data.obj.amfreland.jasmineRiceCan
                  : res.data.obj.belovia.jasmineRiceCan,
              riceBranOil:
                i == 0
                  ? res.data.obj.amfreland.riceBranOil
                  : res.data.obj.belovia.riceBranOil,
              riceEnergyDrink:
                i == 0
                  ? res.data.obj.amfreland.riceEnergyDrink
                  : res.data.obj.belovia.riceEnergyDrink
            };
            this.worldData.push(data);
          }
        }
      });
    },
    fetchDataSoldQ() {
      HTTP.get("/soldq/" + this.room._id + "/" + this.room.currentRound).then(
        res => {

          if (res.data.success) {
            let productMax = [];
            let allTeam = [];
            let firstArray = this.room.groups.filter(
              search => search._id == this.user._id
            );
            let notUser = this.room.groups.filter(
              search => search._id != this.user._id
            );

            notUser.unshift(firstArray[0]);

            notUser.map(item => {
              let data = {};
              let filterUser = res.data.objUser.filter(
                search => search.groupId == item._id
              );
              for (let x = 0; x < 2; x++) {
                if (x == 0) {
                  data.country = "Amfreland";
                } else {
                  data.country = "Belovia";
                }

                let whiteRiceA = res.data.obj.amfreland.whiteRice.filter(
                  se => se.groupId === item._id
                );
                let whiteRiceB = res.data.obj.belovia.whiteRice.filter(
                  se => se.groupId === item._id
                );

                let jasmineRiceA = res.data.obj.amfreland.jasmineRice.filter(
                  se => se.groupId === item._id
                );
                let jasmineRiceB = res.data.obj.belovia.jasmineRice.filter(
                  se => se.groupId === item._id
                );

                let whiteRiceCanA = res.data.obj.amfreland.whiteRiceCan.filter(
                  se => se.groupId === item._id
                );
                let whiteRiceCanB = res.data.obj.belovia.whiteRiceCan.filter(
                  se => se.groupId === item._id
                );

                let jasmineRiceCanA = res.data.obj.amfreland.jasmineRiceCan.filter(
                  se => se.groupId === item._id
                );
                let jasmineRiceCanB = res.data.obj.belovia.jasmineRiceCan.filter(
                  se => se.groupId === item._id
                );

                let riceBranOilA = res.data.obj.amfreland.riceBranOil.filter(
                  se => se.groupId === item._id
                );
                let riceBranOilB = res.data.obj.belovia.riceBranOil.filter(
                  se => se.groupId === item._id
                );

                let riceEnergyDrinkA = res.data.obj.amfreland.riceEnergyDrink.filter(
                  se => se.groupId === item._id
                );
                let riceEnergyDrinkB = res.data.obj.belovia.riceEnergyDrink.filter(
                  se => se.groupId === item._id
                );

                data._id = item._id;
                data.name = item.name;
                data.x = x;

                data.cash = filterUser.length > 0 ? filterUser[0].cash : 0;
                data.branding =
                  filterUser.length > 0 ? filterUser[0].branding : 0;
                data.rd = filterUser.length > 0 ? filterUser[0].rd : 0;

                data.whiteRice = {
                  sold:
                    x == 0
                      ? whiteRiceA.length > 0
                        ? whiteRiceA[0].s
                        : 0
                      : whiteRiceB.length > 0
                      ? whiteRiceB[0].s
                      : 0,
                  price:
                    x == 0
                      ? whiteRiceA.length > 0
                        ? whiteRiceA[0].p
                        : 0
                      : whiteRiceB.length > 0
                      ? whiteRiceB[0].p
                      : 0,
                  soldq:
                    x == 0
                      ? whiteRiceA.length > 0
                        ? whiteRiceA[0].q
                        : 0
                      : whiteRiceB.length > 0
                      ? whiteRiceB[0].q
                      : 0,
                  total:
                    (whiteRiceA.length > 0 ? whiteRiceA[0].s : 0) +
                    (whiteRiceB.length > 0 ? whiteRiceB[0].s : 0)
                };
                data.jasmineRice = {
                  sold:
                    x == 0
                      ? jasmineRiceA.length > 0
                        ? jasmineRiceA[0].s
                        : 0
                      : jasmineRiceB.length > 0
                      ? jasmineRiceB[0].s
                      : 0,
                  price:
                    x == 0
                      ? jasmineRiceA.length > 0
                        ? jasmineRiceA[0].p
                        : 0
                      : jasmineRiceB.length > 0
                      ? jasmineRiceB[0].p
                      : 0,
                  soldq:
                    x == 0
                      ? jasmineRiceA.length > 0
                        ? jasmineRiceA[0].q
                        : 0
                      : jasmineRiceB.length > 0
                      ? jasmineRiceB[0].q
                      : 0,
                  total:
                    (jasmineRiceA.length > 0 ? jasmineRiceA[0].s : 0) +
                    (jasmineRiceB.length > 0 ? jasmineRiceB[0].s : 0)
                };
                data.whiteRiceCan = {
                  sold:
                    x == 0
                      ? whiteRiceCanA.length > 0
                        ? whiteRiceCanA[0].s
                        : 0
                      : whiteRiceCanB.length > 0
                      ? whiteRiceCanB[0].s
                      : 0,
                  price:
                    x == 0
                      ? whiteRiceCanA.length > 0
                        ? whiteRiceCanA[0].p
                        : 0
                      : whiteRiceCanB.length > 0
                      ? whiteRiceCanB[0].p
                      : 0,
                  soldq:
                    x == 0
                      ? whiteRiceCanA.length > 0
                        ? whiteRiceCanA[0].q
                        : 0
                      : whiteRiceCanB.length > 0
                      ? whiteRiceCanB[0].q
                      : 0,
                  total:
                    (whiteRiceCanA.length > 0 ? whiteRiceCanA[0].s : 0) +
                    (whiteRiceCanB.length > 0 ? whiteRiceCanB[0].s : 0)
                };
                data.jasmineRiceCan = {
                  sold:
                    x == 0
                      ? jasmineRiceCanA.length > 0
                        ? jasmineRiceCanA[0].s
                        : 0
                      : jasmineRiceCanB.length > 0
                      ? jasmineRiceCanB[0].s
                      : 0,
                  price:
                    x == 0
                      ? jasmineRiceCanA.length > 0
                        ? jasmineRiceCanA[0].p
                        : 0
                      : jasmineRiceCanB.length > 0
                      ? jasmineRiceCanB[0].p
                      : 0,
                  soldq:
                    x == 0
                      ? jasmineRiceCanA.length > 0
                        ? jasmineRiceCanA[0].q
                        : 0
                      : jasmineRiceCanB.length > 0
                      ? jasmineRiceCanB[0].q
                      : 0,
                  total:
                    (jasmineRiceCanA.length > 0 ? jasmineRiceCanA[0].s : 0) +
                    (jasmineRiceCanB.length > 0 ? jasmineRiceCanB[0].s : 0)
                };
                data.riceBranOil = {
                  sold:
                    x == 0
                      ? riceBranOilA.length > 0
                        ? riceBranOilA[0].s
                        : 0
                      : riceBranOilB.length > 0
                      ? riceBranOilB[0].s
                      : 0,
                  price:
                    x == 0
                      ? riceBranOilA.length > 0
                        ? riceBranOilA[0].p
                        : 0
                      : riceBranOilB.length > 0
                      ? riceBranOilB[0].p
                      : 0,
                  soldq:
                    x == 0
                      ? riceBranOilA.length > 0
                        ? riceBranOilA[0].q
                        : 0
                      : riceBranOilB.length > 0
                      ? riceBranOilB[0].q
                      : 0,
                  total:
                    (riceBranOilA.length > 0 ? riceBranOilA[0].s : 0) +
                    (riceBranOilB.length > 0 ? riceBranOilB[0].s : 0)
                };
                data.riceEnergyDrink = {
                  sold:
                    x == 0
                      ? riceEnergyDrinkA.length > 0
                        ? riceEnergyDrinkA[0].s
                        : 0
                      : riceEnergyDrinkB.length > 0
                      ? riceEnergyDrinkB[0].s
                      : 0,
                  price:
                    x == 0
                      ? riceEnergyDrinkA.length > 0
                        ? riceEnergyDrinkA[0].p
                        : 0
                      : riceEnergyDrinkB.length > 0
                      ? riceEnergyDrinkB[0].p
                      : 0,
                  soldq:
                    x == 0
                      ? riceEnergyDrinkA.length > 0
                        ? riceEnergyDrinkA[0].q
                        : 0
                      : riceEnergyDrinkB.length > 0
                      ? riceEnergyDrinkB[0].q
                      : 0,
                  total:
                    (riceEnergyDrinkA.length > 0 ? riceEnergyDrinkA[0].s : 0) +
                    (riceEnergyDrinkB.length > 0 ? riceEnergyDrinkB[0].s : 0)
                };
                let dataArr = JSON.parse(JSON.stringify(data));
   
                allTeam.push(dataArr);
              }
            });
            this.saleData = allTeam;
            for (let i = 0; i < 2; i++) {
              let country = {
                name: i == 0 ? "Amfreland" : "Belovia",
                minP1:
                  i == 0
                    ? res.data.obj.amfrelandPrice.min.whiteRice
                    : res.data.obj.beloviaPrice.min.whiteRice,
                maxP1:
                  i == 0
                    ? res.data.obj.amfrelandPrice.max.maxWhiteRice
                    : res.data.obj.beloviaPrice.max.maxWhiteRice,
                minP2:
                  i == 0
                    ? res.data.obj.amfrelandPrice.min.jasmineRice
                    : res.data.obj.beloviaPrice.min.jasmineRice,
                maxP2:
                  i == 0
                    ? res.data.obj.amfrelandPrice.max.maxJasmineRice
                    : res.data.obj.beloviaPrice.max.maxJasmineRice,
                minP3:
                  i == 0
                    ? res.data.obj.amfrelandPrice.min.whiteRiceCan
                    : res.data.obj.beloviaPrice.min.whiteRiceCan,
                maxP3:
                  i == 0
                    ? res.data.obj.amfrelandPrice.max.maxWhiteRiceCan
                    : res.data.obj.beloviaPrice.max.maxWhiteRiceCan,
                minP4:
                  i == 0
                    ? res.data.obj.amfrelandPrice.min.jasmineRiceCan
                    : res.data.obj.beloviaPrice.min.jasmineRiceCan,
                maxP4:
                  i == 0
                    ? res.data.obj.amfrelandPrice.max.maxJasmineRiceCan
                    : res.data.obj.beloviaPrice.max.maxJasmineRiceCan,
                minP5:
                  i == 0
                    ? res.data.obj.amfrelandPrice.min.riceBranOil
                    : res.data.obj.beloviaPrice.min.riceBranOil,
                maxP5:
                  i == 0
                    ? res.data.obj.amfrelandPrice.max.maxRiceBranOil
                    : res.data.obj.beloviaPrice.max.maxRiceBranOil,
                minP6:
                  i == 0
                    ? res.data.obj.amfrelandPrice.min.riceEnergyDrink
                    : res.data.obj.beloviaPrice.min.riceEnergyDrink,
                maxP6:
                  i == 0
                    ? res.data.obj.amfrelandPrice.max.maxRiceEnergyDrink
                    : res.data.obj.beloviaPrice.max.maxRiceEnergyDrink
              };
              productMax.push(country);
            }
            this.priceData = productMax;
          }
        }
      );
    },
    updateInvestment(round) {
      
      let index = round > 1 ? round - 2 : 0;
      let obj = {
        branding: this.groupAllData.accounts[index].branding,
        rd: this.groupAllData.accounts[index].rd,
        warehouse: this.groupAllData.accounts[index].warehouse,
        takeBranding: this.groupAllData.accounts[index].takeBranding,
        takeRd: this.groupAllData.accounts[index].takeRd
      };

      HTTP.put(`investment/${this.room._id}/${this.user._id}/${round}`, obj)
        .then(res => {

          if (res.data.success) {
            
            this.fetchGroupAllData();
          }
        })
        .catch(e => {
          console.log("updateInvestment error", e);
        });
    },
    refundPrinciple(round) {
      let credit = 0;
      let principle = 0;
      for (let index = 0; index < round; index++) {
        credit = credit + this.groupAllData.accounts[index].credit;
        principle = principle + this.groupAllData.accounts[index].principle;
      }

      let index = this.room.currentRound - 2;
      let obj = {
        interest: ((credit - principle) * 5) / 100
      };

      HTTP.put(`/principle/${this.user._id}/${round}`, obj)
        .then(res => {})
        .catch(e => {
          console.log("refundPrinciple error", e);
        });
    },
    fetchGroupAllData() {
      let obj = {
        roomId: this.room._id,
        groupId: this.user._id
      };

      this.$store.dispatch("FETCH_GROUP_ALL_DATA", obj);
    }
  }
};
</script>

<style scoped>
.modalEventCard {
  transition: all 0.8s ease;
  min-height: 120px;
}
.indicatorsBdr {
  border-right: 1px solid #dcdfe6;
}
</style>