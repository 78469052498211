<template>
    <div v-loading="loading">
        <div>
            <div class="is-flex js-between ai-center pd-b-5">
                <div></div>
                <span class="font-16">
                    <span class="font-weight-bold">Total Stock:</span>  
                    {{totalInventory}} / {{maxInventory}}
                </span>
            </div>
        </div>
        <SaleList :saleOrder="saleOrder" :loading="loading" />
        <div class="text-right pd-t-4">
            <el-row justify="end" type="flex">
                <el-col :span="4">
                    <el-button class="w-100" @click="sentToSo"  v-if="room.current === 'Sale Order'" type="primary" icon="el-icon-s-order" round :loading="loading">{{(order.status === 1)?'Again':'Send'}}</el-button>
                </el-col>
            </el-row>
            <p v-if="sendOrderTime != '' && order.status === 1">เสนอราคาล่าสุดเมื่อ {{sendOrderTime | timeTh}}</p>
            <div v-if="room.mode == 'single'  && room.current === 'Sale Order'" class="font-14 mg-t-5">
                * หากไม่ต้องการเสนอขายสินค้ากดปุ่ม Send เพื่อทำการไปต่อ
            </div>
        </div>
        <div v-if="sendOrderTime != '' && order.status === 1 && room.current === 'Sale Order' && room.mode !== 'single'" class="animated slideInDown banner-top">
            กรุณารอผู้ประกอบการท่านอื่นส่งออเดอร์การขาย
        </div>
        


<!--         <el-dialog :visible.sync="modalNewRound" width="30%">
            <div class="text-center">
                <h3>เริ่มต้นเสนอราคารอบที่ {{room.currentRound}}</h3>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="modalNewRound = false">OK</el-button>
            </span>
        </el-dialog> -->
    </div>
</template>

<script>
import DashboardTemplate from '@/template/DashboardTemplate';
import SaleList from './SaleList';
import {alertFailError,alertCatchError} from '@/service/alertMsg';
import {checkUndefinedNumber} from '@/service/checkTypeValue';
import {HTTP} from'@/service/axios'

export default {
    props:{
        activeTab:{
            type:String,
            default(){
                return ''
            }
        }
    },
    components:{
        DashboardTemplate,
        SaleList
    },
    computed: {
        limitedPrice(){
            return this.$store.state.limitedPrice
        },
        groupAllData(){
            return this.$store.state.groupAllData
        },
        user(){
            return this.$store.state.user
        },
        room(){
            return this.$store.state.room
        },
        totalInventory(){   
            let inv = 0
            this.saleOrder.map((item)=>{
                inv = inv + (item.stock - (item.saleQuantityAf + item.saleQuantityBv))
            })
            return inv
        },
        accounts(){
            if(this.groupAllData === null){ return '' }
            let filter = this.groupAllData.accounts.filter(filter => filter.round === this.room.currentRound)
            if(filter.length > 0){
                return filter[0]
            }else{
                return ''
            }
        },
        order(){
            if(this.groupAllData === null){ return '' }
            let filter = this.groupAllData.saleOrder.filter(filter => filter.round === this.room.currentRound)
            if(filter.length > 0){
                return filter[0]
            }else{
                return ''
            }
        },
        maxInventory(){
            return  this.accounts.warehouse + 200
        }
    },
    watch: {
		activeTab(){
            if(this.activeTab === 'saleOrder'){
                this.$store.dispatch('FETCH_LIMITED_PRICE')
                this.fetchGroupsAllData()
                if(this.room.currentRound > 1 && this.room.current == 'Sale Order' && this.order.status === 0){
                    this.modalNewRound = true
                }
            }
		}
	},
    data() {
        return {
            modalNewRound:false,
            saleOrder: [], 
            loading:false, 
            sendOrderTime:''
        }
    },
    mounted() {
        if(this.room.currentRound > 1 && this.room.current == 'Sale Order' && this.order.status === 0){
            this.modalNewRound = true
        }
        this.loading = true
        this.$store.dispatch('FETCH_LIMITED_PRICE')
        this.fetchGroupsAllData()
    },
    methods: { 
        async fetchStatus(){
            let obj ={
                roomId:this.room._id,
                groupId:this.user._id,
            }
            await this.$store.dispatch('FETCH_GROUP_ALL_DATA',obj)
        },
        async fetchGroupsAllData(){
            let obj ={
                roomId:this.room._id,
                groupId:this.user._id,
            }
            await this.$store.dispatch('FETCH_GROUP_ALL_DATA',obj)

                const saleOrderdefault=[
                {
                    id:1,
                    product: 'ข้าวสารขาว',
                    salePriceAf: this.limitedPrice.whiteRice.min,
                    saleQuantityAf: 0,
                    salePriceBv: this.limitedPrice.whiteRice.min,
                    saleQuantityBv: 0,
                    stock:this.groupAllData.inventory.whiteRice,
                    min:this.limitedPrice.whiteRice.min,
                    max:this.limitedPrice.whiteRice.max,
                    balance:this.groupAllData.inventory.whiteRice,
                },
                {
                    id:2,
                    product: 'ข้าวสารหอม',
                    salePriceAf: this.limitedPrice.jasmineRice.min,
                    saleQuantityAf: 0,
                    salePriceBv: this.limitedPrice.jasmineRice.min,
                    saleQuantityBv: 0,
                    stock:this.groupAllData.inventory.jasmineRice,
                    min:this.limitedPrice.jasmineRice.min,
                    max:this.limitedPrice.jasmineRice.max,
                    balance:this.groupAllData.inventory.jasmineRice,
                },
                {
                    id:3,
                    product: 'ข้าวขาวนึ่งกระป๋อง',
                    salePriceAf: this.limitedPrice.whiteRiceCan.min,
                    saleQuantityAf: 0,
                    salePriceBv: this.limitedPrice.whiteRiceCan.min,
                    saleQuantityBv: 0,
                    stock:this.groupAllData.inventory.whiteRiceCan,
                    min:this.limitedPrice.whiteRiceCan.min,
                    max:this.limitedPrice.whiteRiceCan.max,
                    balance:this.groupAllData.inventory.whiteRiceCan,
                },
                {
                    id:4,
                    product: 'ข้าวหอมนึ่งกระป๋อง',
                    salePriceAf: this.limitedPrice.jasmineRiceCan.min,
                    saleQuantityAf: 0,
                    salePriceBv: this.limitedPrice.jasmineRiceCan.min,
                    saleQuantityBv: 0,
                    stock:this.groupAllData.inventory.jasmineRiceCan,
                    min:this.limitedPrice.jasmineRiceCan.min,
                    max:this.limitedPrice.jasmineRiceCan.max,
                    balance:this.groupAllData.inventory.jasmineRiceCan,
                },
                {
                    id:5,
                    product: 'น้ำมันรำข้าวสกัดบริสุทธิ์',
                    salePriceAf: this.limitedPrice.riceBranOil.min,
                    saleQuantityAf: 0,
                    salePriceBv: this.limitedPrice.riceBranOil.min,
                    saleQuantityBv: 0,
                    stock:this.groupAllData.inventory.riceBranOil,
                    min:this.limitedPrice.riceBranOil.min,
                    max:this.limitedPrice.riceBranOil.max,
                    balance:this.groupAllData.inventory.riceBranOil,
                },
                {
                    id:6,
                    product: 'เครื่องดื่มข้าวชูกำลัง',
                    salePriceAf: this.limitedPrice.riceEnergyDrink.min,
                    saleQuantityAf: 0,
                    salePriceBv: this.limitedPrice.riceEnergyDrink.min,
                    saleQuantityBv: 0,
                    stock:this.groupAllData.inventory.riceEnergyDrink,
                    min:this.limitedPrice.riceEnergyDrink.min,
                    max:this.limitedPrice.riceEnergyDrink.max,
                    balance:this.groupAllData.inventory.riceEnergyDrink,
                },
            ]

            this.saleOrder = saleOrderdefault
            setTimeout(()=>{
             
                this.loading = false
            },500)
            
        },
        sentToSo(){
            this.sendOrderTime = new Date()
            this.loading = true
            let obj ={
                groupId:this.user._id,
                round: this.room.currentRound,
                amfreland:{
                    whiteRice : {
                        p : this.checkUndefinedNumber(this.saleOrder[0].salePriceAf), 
                        q : this.checkUndefinedNumber(this.saleOrder[0].saleQuantityAf)
                    }, 
                    jasmineRice : {
                        p : this.checkUndefinedNumber(this.saleOrder[1].salePriceAf), 
                        q : this.checkUndefinedNumber(this.saleOrder[1].saleQuantityAf)
                    }, 
                    whiteRiceCan : {
                        p : this.checkUndefinedNumber(this.saleOrder[2].salePriceAf), 
                        q : this.checkUndefinedNumber(this.saleOrder[2].saleQuantityAf)
                    }, 
                    jasmineRiceCan : {
                        p : this.checkUndefinedNumber(this.saleOrder[3].salePriceAf), 
                        q : this.checkUndefinedNumber(this.saleOrder[3].saleQuantityAf)
                    }, 
                    riceBranOil : {
                        p : this.checkUndefinedNumber(this.saleOrder[4].salePriceAf), 
                        q : this.checkUndefinedNumber(this.saleOrder[4].saleQuantityAf)
                    }, 
                    riceEnergyDrink : {
                        p : this.checkUndefinedNumber(this.saleOrder[5].salePriceAf), 
                        q : this.checkUndefinedNumber(this.saleOrder[5].saleQuantityAf)
                    }
                },
                belovia:{
                    whiteRice : {
                        p : this.checkUndefinedNumber(this.saleOrder[0].salePriceBv), 
                        q : this.checkUndefinedNumber(this.saleOrder[0].saleQuantityBv)
                    }, 
                    jasmineRice : {
                        p : this.checkUndefinedNumber(this.saleOrder[1].salePriceBv), 
                        q : this.checkUndefinedNumber(this.saleOrder[1].saleQuantityBv)
                    }, 
                    whiteRiceCan : {
                        p : this.checkUndefinedNumber(this.saleOrder[2].salePriceBv), 
                        q : this.checkUndefinedNumber(this.saleOrder[2].saleQuantityBv)
                    }, 
                    jasmineRiceCan : {
                        p : this.checkUndefinedNumber(this.saleOrder[3].salePriceBv), 
                        q : this.checkUndefinedNumber(this.saleOrder[3].saleQuantityBv)
                    }, 
                    riceBranOil : {
                        p : this.checkUndefinedNumber(this.saleOrder[4].salePriceBv), 
                        q : this.checkUndefinedNumber(this.saleOrder[4].saleQuantityBv)
                    }, 
                    riceEnergyDrink : {
                        p : this.checkUndefinedNumber(this.saleOrder[5].salePriceBv), 
                        q : this.checkUndefinedNumber(this.saleOrder[5].saleQuantityBv)
                    }
                }
            }

            HTTP.put(`/sale/order/${this.room._id}`,obj)
            .then(res=>{
                if(res.data.success){
                    this.fetchStatus()
                    this.$socket.client.emit('checkPointSoldQ',{roomId :this.room._id,round:this.room.currentRound})
                    setTimeout(()=>{
                        this.$message({
                            message: 'Send to sale order success',
                            type: 'success'
                        });
                    },500)     
                }else{
                    this.alertFailError()
                }
            })
            .catch((e)=>{
                this.alertCatchError()
                console.log("sentToSo error",e)
            })
            .finally(()=>{
                if(this.room.mode == 'single'){
                    let dataCom = {
                        round: this.room.currentRound,
                        roomId: this.room._id
                    };
                    HTTP.post("/comrate", dataCom).then(async (res) => {
    
                        if (res.data.success || res.data.data == 'announced soldQ this round') {
                          this.room.current = 'Sold Q Announcement'
                          await this.$store.dispatch('updateCurrent',this.room);
                          await this.$socket.client.emit('getRoom',{roomNumber:this.room.roomNumber})
                        }
                    })
                }else{
                    setTimeout(()=>{
                        this.loading = false
                    },500)
                }      
            })
        }
    },
}
</script>

<style scoped>

</style>