import { render, staticRenderFns } from "./Invest.vue?vue&type=template&id=259d879d&scoped=true&"
import script from "./Invest.vue?vue&type=script&lang=js&"
export * from "./Invest.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "259d879d",
  null
  
)

export default component.exports