<template>
    <div>
        <el-table
            :data="saleOrder"
            border
            :cell-class-name="({row, column, rowIndex, columnIndex})=>setCellColor(row, column, rowIndex, columnIndex)"
            :header-cell-class-name="({row, column, rowIndex, columnIndex})=>setHeaderColor(row, column, rowIndex, columnIndex)"
            empty-text='No Data'
            class="width-max">
                <el-table-column
                    prop="product"
                    header-align="center"
                    label="Product list"
                >
                    <template slot-scope="scope" >
                        <p class="pd-less mg-less">{{scope.row.product}}</p>
                        <p v-if="scope.row.id == 5 || scope.row.id == 6" class="pd-less mg-less font-12 color-light">* ต้องมี R&D ระดับ 4 ถึงจะผลิตได้</p>
                    </template>
                </el-table-column>
                <el-table-column label="Amfreland" header-align="center">
                    <el-table-column
                        prop="salePrice"
                        header-align="center"
                        label="Price (USD)"
                        width="220">
                        <template slot-scope="scope">
                            <el-input-number  @change="checkStock(scope.row)" :min="scope.row.min" :max="scope.row.max" v-model="scope.row.salePriceAf"  :disabled="((scope.row.id === 5 || scope.row.id === 6) && accounts.rd < 4 )" step-strictly size="small"  class="width-max"></el-input-number>
                            <div class="is-flex js-between ai-center">
                                <span class="font-12">Min: ${{scope.row.min}}</span>
                                <span class="font-12">Max: ${{scope.row.max}}</span>
                            </div>   
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="saleQuantity"
                        header-align="center"
                        label="Quantity"
                        width="220">
                        <template slot-scope="scope" >
                            <el-input-number  
                            :min="0" 
                            :max="scope.row.balance+scope.row.saleQuantityAf" 
                            v-model="scope.row.saleQuantityAf" 
                            @change="updateBalance(scope.row)"
                            :disabled="((scope.row.id === 5 || scope.row.id === 6) && accounts.rd < 4 )"
                            size="small"  
                            step-strictly 
                            class="width-max" ></el-input-number>
                            <div class="is-flex js-between ai-center">
                                <span class="font-12">ระบุจำนวนที่ต้องการขาย</span>
                            </div>   
                        </template>
                    </el-table-column>
                </el-table-column>
                <el-table-column label="Belovia" header-align="center">
                    <el-table-column
                        prop="salePrice"
                        header-align="center"
                        label="Price (USD)"
                        width="220">
                        <template slot-scope="scope">
                            <el-input-number  @change="checkStock(scope.row)" :min="scope.row.min" :max="scope.row.max" v-model="scope.row.salePriceBv"  :disabled="((scope.row.id === 5 || scope.row.id === 6) && accounts.rd < 4 )" step-strictly size="small"  class="width-max"></el-input-number>
                            <div class="is-flex js-between ai-center">
                                <span class="font-12">Min: ${{scope.row.min}}</span>
                                <span class="font-12">Max: ${{scope.row.max}}</span>
                            </div>   
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="saleQuantity"
                        header-align="center"
                        label="Quantity"
                        width="220">
                        <template slot-scope="scope" >
                            <el-input-number  
                                v-model="scope.row.saleQuantityBv" 
                                :min="0"  
                                :max="scope.row.balance+scope.row.saleQuantityBv" 
                                :disabled="((scope.row.id === 5 || scope.row.id === 6) && accounts.rd < 4 )"
                                @change="updateBalance(scope.row)"
                                size="small"  
                                step-strictly 
                                class="width-max" >
                            </el-input-number>
                            <div class="is-flex js-between ai-center">
                                <span class="font-12">ระบุจำนวนที่ต้องการขาย</span>
                            </div>   
                        </template>
                    </el-table-column>
                </el-table-column>
                <el-table-column
                    prop="stock"
                    header-align="center"
                    align="center"
                    label="Balance stock"
                    width="120"
                >
                    <template slot-scope="scope" >
                        <span>{{calBalance(scope.row.stock,checkUndefinedNumber(scope.row.saleQuantityAf),checkUndefinedNumber(scope.row.saleQuantityBv))}}</span>
                    </template>
                </el-table-column>
        </el-table>
    </div>
</template>

<script>
import {checkUndefinedNumber} from '@/service/checkTypeValue';
export default {
    props:{
        saleOrder:{
            type: Array,
            default() {
                return [];
            }
        }
    },
    computed: {
        groupAllData(){
            return this.$store.state.groupAllData
        },
        room(){
            return this.$store.state.room
        },
        accounts(){
            if(this.groupAllData === null){ return '' }
            let filter = this.groupAllData.accounts.filter(filter => filter.round === this.room.currentRound)
            if(filter.length > 0){
                return filter[0]
            }else{
                return ''
            }
        },
    },
   mounted() {
       
   },
   methods: {
      calBalance(stock,af,bv){
        return stock - (af + bv)
      },
      checkStock(model){
          
          if(typeof model.salePriceAf === 'undefined'){
              model.salePriceAf = model.min
          }

          if(typeof model.salePriceBv === 'undefined'){
              model.salePriceBv = model.min
          }

          if(typeof model.saleQuantityAf === 'undefined'){
              model.saleQuantityAf = 0
          }

          if(typeof model.saleQuantityBv === 'undefined'){
              model.saleQuantityBv = 0
          }
          
      },
      updateBalance(model){
          this.checkStock(model)
          model.balance = this.calBalance(model.stock,model.saleQuantityAf,model.saleQuantityBv)
      },
        setHeaderColor(row, column, rowIndex, columnIndex){

            if ((columnIndex == 1 && rowIndex == 1) || (columnIndex == 3 && rowIndex == 1)) {
                return 'cell-quantity'
            }else if (rowIndex == 1) {
                return 'cell-price'
            }else if(columnIndex == 1 && rowIndex == 0){
                return 'header-am'
            }else if(columnIndex == 2 && rowIndex == 0){
                return 'header-bl'
            }

        } ,
     setCellColor(row, column, rowIndex, columnIndex){
            if (columnIndex == 1 || columnIndex == 3) {
                return 'cell-price'
            }else if (columnIndex == 2 || columnIndex == 4) {
                return 'cell-quantity'
            }
        }   
   },
}
</script>

<style scoped>

</style>