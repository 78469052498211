<template>
    <div>
        <div v-if="room.current === 'Financial product'" class="pd-b-5">
            <h2>ผลิตภัณฑ์การเงิน</h2>
            <div class="is-flex ">
                <el-tooltip class="item" effect="dark" content="สินเชื่อ ดอกเบี้ย 5 % ต่อรอบ จนกว่าจะคืนเงินต้น (ตั้งแต่ ฿10,000 - ฿2,000,000)" placement="bottom">
                    <el-checkbox v-model="creditCheck" label="สินเชื่อ" border @change="checkCredit" class="mg-l-4 mg-r-less" :disabled="accounts.status === 1"></el-checkbox>
                </el-tooltip>
                <div v-if="creditCheck" class="creditBox mg-l-4">
                    <el-input-number  :min="10000"  :max="2000000" v-model="credit"   step-strictly :step="10000" class="width-max" :disabled="accounts.status === 1"></el-input-number>
                    <p class="mg-y-5 creditDes">สินเชื่อ (ตั้งแต่ ฿10,000 - ฿2,000,000)</p>
                </div>
                <el-tooltip class="item" effect="dark" content="ประกันการส่งออก ค่าเบี้ย 1 % ของมูลค่าส่งออก" placement="bottom">
                    <el-checkbox v-model="insurance" @change="calInsurance" :label="((balanceRound[room.currentRound-1]+credit) < insuranceAmount && accounts.status !== 1)?'เงินไม่พอซื้อประกัน':(insurance)?'ประกัน ('+$options.filters.comma(insuranceAmount)+')':'ประกัน'" border  :disabled="accounts.status === 1 || (balanceRound[room.currentRound-1]+credit) < insuranceAmount" class="mg-l-4 mg-r-less"></el-checkbox>
                </el-tooltip>
                <el-tooltip class="item" effect="dark" content="Forward Contract Swap Point -0.25" placement="bottom">
                    <el-checkbox v-model="forward" label="Forward contract" border  class="mg-l-4 mg-r-less" :disabled="accounts.status === 1"></el-checkbox>
                </el-tooltip>
                <el-button @click="buyfinance" type="primary" plain round class="mg-l-4" :loading="loadingFinance" :disabled="accounts.status === 1">Confirm</el-button>
            </div>
        </div>
        <div v-if="room.current === 'Financial product'" class="pd-b-5">
            <h2>คืนเงินต้น</h2>Financial product
            <div class="is-flex ">
                <div  class="creditBox mg-l-4">
                    <el-input-number  :min="0" :max="creditBalanceMax" v-model="principle"   step-strictly class="width-max"></el-input-number>
                    <p class="mg-y-5 creditDes">สินเชื่อคงเหลือ (฿{{creditBalance - checkUndefinedNumber(principle) | comma}})</p>
                </div>
                <el-button @click="refundPrinciple" type="primary" plain round class="mg-l-4"  :loading="loadingRefund">คืนเงิน</el-button>
            </div>
        </div>
        <div>
            <h2>เงินคงเหลือ</h2>
            <AccountList :accounting="accounting" :calBalanceLast="calBalanceLast" :loading="loading"/>
        </div>
        <div v-if="room.mode == 'single' && room.current === 'Financial product' " class="mg-t-3 text-right">
            <el-row justify="end" type="flex">
                <el-col :span="4">
                    <el-button type="primary" class="w-100" @click="accounts.status === 1 ? $router.push('/random-event-card') : confirmModal = true" round >Next</el-button>
                </el-col>
            </el-row>
        </div>
        <div v-if="((accounts.status === 1 && room.current === 'Financial product') || room.current === 'Business Event Card') && room.mode != 'single'" class="animated slideInDown banner-top">
            <span v-if="accounts.status === 1 && room.current === 'Financial product'">กรุณารอผู้ประกอบการท่านอื่นซื้อผลิตภัณฑ์ทางการเงิน</span>
            <span v-else ><i class="el-icon-loading"></i> <span class="text-change-color">กำลังรอผล Business Event</span></span>
        </div>
        <el-dialog :visible.sync="confirmModal" width="30%">
            <div>
                <h3 class="text-center">คุณยืนยันไม่ซื้อผลิตภัณฑ์ทางการเงินใช่หรือไม่ ?</h3>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button class="mg-r-3" @click="confirmModal = false">Cancel</el-button>
                <router-link to="/random-event-card">
                    <el-button type="primary">Confirm</el-button>
                </router-link>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import AccountList from './AccountList';
import {alertFailError,alertCatchError} from '@/service/alertMsg';
import {checkUndefinedNumber} from '@/service/checkTypeValue';
import {HTTP} from'@/service/axios'

export default {
    props:{
        activeTab:{
            type:String,
            default(){
                return ''
            }
        }
    },
    components:{
      AccountList
    },
    computed: {
        user(){
            return this.$store.state.user
        },
        room(){
            return this.$store.state.room
        },
        groupAllData(){
            return this.$store.state.groupAllData
        },
        accounts(){
            if(this.groupAllData === null){ return '' }
            let filter = this.groupAllData.accounts.filter(filter => filter.round === this.room.currentRound)
            if(filter.length > 0){
                return filter[0]
            }else{
                return ''
            }
        },
        finanProducts(){
            return this.$store.state.finanProducts
        },
        creditBalance(){
            if(this.accounting.length > 0){
                let account = this.accounting[5]
                return account['round'+this.room.currentRound]
            }else{
                return 0
            }   
        },
        principleConst(){
            if(this.accounting.length > 0){
                let account = this.accounting[4]
                return account['round'+this.room.currentRound]
            }else{
                return 0
            }   
        },
        creditBalanceMax(){
            if(this.balanceRound.length > 0 && this.creditBalance > this.balanceRound[this.room.currentRound-1]){
                return Math.floor(this.balanceRound[this.room.currentRound-1])
            }else{
               
                return Math.floor(this.creditBalance)
            }
        }
    },
    watch: {
		async activeTab(){
            this.credit = 0
            this.creditCheck = false
            this.insurance = false
            this.forward = false
            this.insuranceAmount = 0
            this.principle = 0
            
            if(this.activeTab === 'accounting'){
                await this.fetchGroupAllData()
                await this.setFinanProducts()
                this.calInsurance() 
            }
            
		}
	},
    data() {
        return {
            confirmModal:false,
            accounting:[],
            credit:0,
            creditCheck:false,
            insurance:false,
            forward:false,
            insuranceAmount:0,
            loading:false,
            principle:0,
            balanceRound:[],
            loadingFinance:false,
            loadingRefund:false
        }
    },
    async mounted() {
        this.loading = true
        await this.fetchGroupAllData()
        await this.setFinanProducts()
        this.calInsurance()
    },
    methods: {
        checkCredit(){
            if(!this.creditCheck){
                this.credit = 0
            }

            if(this.balanceRound[this.room.currentRound-1]+this.credit < this.insuranceAmount){
                this.insurance = false
                this.insuranceAmount = 0
                this.calInsurance()
            }
        },
        setFinanProducts(){
            if(this.finanProducts !== null){
                this.credit = this.finanProducts.credit
                this.creditCheck = this.finanProducts.creditCheck
                this.insurance = this.finanProducts.insurance
                this.forward = this.finanProducts.forward
                this.insuranceAmount = this.finanProducts.insuranceAmount
            }
        },
        refundPrinciple(status){
            let obj = {}
            this.loading = true
            this.loadingRefund = true
            if(status !== 'interest'){
                obj ={
                    principle:Number(this.checkUndefinedNumber(this.principle) + this.principleConst),
                    interest:Number(((this.creditBalance - this.checkUndefinedNumber(this.principle))  * 5) /100)
                }
            }else{
                let credit = (this.room.currentRound > 1)?this.creditBalance:this.credit
                obj ={
                    interest:Number((credit * 5) /100)
                }
            }
            
           
            HTTP.put(`/principle/${this.user._id}/${this.room.currentRound}`,obj)
            .then(res=>{
                if(res.data.success){
                    this.fetchGroupAllData()
                    if(status !== 'interest'){
                        setTimeout(()=>{
                            this.$message({
                                message: 'คืนเงินต้นเรียเรียบร้อยแล้ว',
                                type: 'success'
                            });
                        },500) 
                        this.principle = 0
                    }
                }else{
                    this.alertFailError()
                }
            })
            .catch((e)=>{
                this.alertCatchError()
                console.log("refundPrinciple error",e)
            })
            .finally(()=>{
                setTimeout(()=>{
                    this.loading = false
                    this.loadingRefund = false
                    if((this.balanceRound[this.room.currentRound-1]+this.credit) < this.insuranceAmount){
                        this.insurance = false
                    }
                },500)
                 
            })
        },
        buyfinance(){
            this.loading = true
            this.loadingFinance = true
            let obj ={
                roomId:this.room._id,
                groupId:this.user._id,
                round:this.room.currentRound,
                creditCheck: this.creditCheck,
                credit: (this.creditCheck)?this.credit:0,
                insuranceCheck: this.insurance,
                insurance:  (this.insurance)?this.checkUndefinedNumber(this.insuranceAmount):0,
                status:1,
                fw: this.forward
            }


            HTTP.put(`/finance`,obj)
            .then((res)=>{
                if(res.data.success){
                    this.fetchGroupAllData().then(()=>{
                        this.refundPrinciple('interest')
                    })
                    
                    setTimeout(()=>{
                        this.$message({
                            message: 'ส่งคำสั่งซื้อเรียบร้อยแล้ว',
                            type: 'success'
                        });
                    },500) 
                    let obj ={
                        credit:this.credit,
                        creditCheck:this.creditCheck,
                        insurance:this.insurance,
                        forward:this.forward,
                        insuranceAmount:this.insuranceAmount,
                    }
                    this.$store.commit('SET_FINAN_PRODUCTS',obj)
                    this.$socket.client.emit('waitingFinancial',{roomId:this.room._id,round:this.room.currentRound})
                }else{
                    this.alertFailError()
                }
            })
            .catch((e)=>{
                this.alertCatchError()
                console.log("buyfinance error",e)
            })
            .finally(()=>{
                setTimeout(()=>{
                    this.loading = false
                    this.loadingFinance = false
                },500)
                 
            })
        },
        getAmount(id,current){
            let account = this.accounting.filter(filter => filter.id === id)
            if(account.length > 0 && typeof account[0]['round'+current] !== 'undefined'){
                return account[0]['round'+current]
            }else{
                return 0
            }
            
        },
        fetchGroupAllData(){
            let obj ={
                roomId:this.room._id,
                groupId:this.user._id,
            }
            return this.$store.dispatch('FETCH_GROUP_ALL_DATA',obj).then(()=>{
                this.fetchAllData()
            })
        },
        async fetchAllData(){
            let accountArray = [{
                id:1,
                name: 'เงินคงเหลือต้นงวด',
                statusMoney:'',
                round1:0,
                round2:0,
                round3:0,
                round4:0,
                round5:0,
                round6:0
            }]
            let income = this.setDataAccount(this.groupAllData.accounts,'income','รายได้','+',2)
            accountArray.push(income)
            let tradeAccountReceivables = this.setDataAccount(this.groupAllData.accounts,'tradeAccountReceivables','รายได้จากลูกหนี้การค้า','+',3)
            accountArray.push(tradeAccountReceivables)
            let credit = this.setDataAccount(this.groupAllData.accounts,'credit','สินเชื่อ','+',4)
            accountArray.push(credit)
            let principle = this.setDataAccount(this.groupAllData.accounts,'principle','คืนเงินต้น','-',5)
            accountArray.push(principle)

            let balanceCredit = []
            for (let x = 0; x < 6; x++) {
                let credit = 0
                let principle = 0
                for (let y = 0; y <= x; y++) {
                    credit = credit + this.groupAllData.accounts[y].credit
                    principle = principle + this.groupAllData.accounts[y].principle  
                }   
      
                let sum = credit - principle
                balanceCredit.push(sum) 
            }
            
         
             let creditBalance = {
                id:6,
                name: 'สินเชื่อคงเหลือ',
                statusMoney:'',
                round1:balanceCredit[0],
                round2:balanceCredit[1],
                round3:balanceCredit[2],
                round4:balanceCredit[3],
                round5:balanceCredit[4],
                round6:balanceCredit[5]
            }
           
            accountArray.push(creditBalance)

            let interest = this.setDataAccount(this.groupAllData.accounts,'interest','ดอกเบี้ย (5% ของสินเชื่อ)','-',7)
            accountArray.push(interest)
            let cost = this.setDataAccount(this.groupAllData.accounts,'cost','ต้นทุนผลิต','-',8)
            accountArray.push(cost)
            let insurance = this.setDataAccount(this.groupAllData.accounts,'insurance','เบี้ยประกัน (1% มูลค่าส่งออก)','-',9)
            accountArray.push(insurance)
            let economicInsights = this.setDataAccount(this.groupAllData.accounts,'economicInsights','Economic Insights','-',10)
            accountArray.push(economicInsights)
            let branding = this.setDataAccount(this.groupAllData.accounts,'brandingAmount','Branding','-',11)
            accountArray.push(branding)
            let rd = this.setDataAccount(this.groupAllData.accounts,'rdAmount','R&D','-',12)
            accountArray.push(rd)
            let warehouse = this.setDataAccount(this.groupAllData.accounts,'warehouseAmount','Warehouse','-',13)
            accountArray.push(warehouse)
            let busniessEvenCard = this.setDataAccount(this.groupAllData.accounts,'busniessEvenCard','Business Event','-',14)
            accountArray.push(busniessEvenCard)
            this.accounting = accountArray

            let balance = {
                id:1,
                name: 'เงินคงเหลือต้นงวด',
                statusMoney:'',
                round1:0,
                round2:(this.accounting.length)
                        ?this.calBalanceLast(this.accounting,'round1',0)
                        :0,
                round3:(this.accounting.length > 0)
                        ?this.calBalanceLast(this.accounting,'round2',this.calBalanceLast(this.accounting,'round1',0))
                        :0,
                round4:(this.accounting.length > 0)
                        ?this.calBalanceLast(this.accounting,'round3',this.calBalanceLast(this.accounting,'round2',this.calBalanceLast(this.accounting,'round1',0)))
                        :0,
                round5:(this.accounting.length > 0)?
                        this.calBalanceLast(this.accounting,'round4',this.calBalanceLast(this.accounting,'round3',this.calBalanceLast(this.accounting,'round2',this.calBalanceLast(this.accounting,'round1',0))))
                        :0,
                round6:
                        (this.accounting.length > 0)
                        ?this.calBalanceLast(this.accounting,'round5',this.calBalanceLast(this.accounting,'round4',this.calBalanceLast(this.accounting,'round3',this.calBalanceLast(this.accounting,'round2',this.calBalanceLast(this.accounting,'round1',0)))))
                        :0
            }
            
            this.accounting[0]=balance
           
            this.setCalBalanceRound()
            this.loading = false
    
        },
        setDataAccount(data,property,name,status,id){
          let inventoryRound = {
            id:id,
            name: name,
            statusMoney:status,
            round1:data[0][property],
            round2:data[1][property],
            round3:data[2][property],
            round4:data[3][property],
            round5:data[4][property],
            round6:data[5][property]
          }

          return inventoryRound
        },
        calBalanceLast(data,round,begin){
            let increase =  0 
            let decrease = 0
            data.map((item)=>{
                if(item.id === 14){
                    decrease = decrease + sumEventMoney(item[round])
                }else if(item.statusMoney === '-' && item.id !== 6){
                    decrease = decrease + item[round]
                }else if(item.id !== 6){
                    let beginMoney = (item.id === 1)?begin:0
                    increase = increase + item[round] + beginMoney
                }
            })
        
            return increase - decrease

            function sumEventMoney(money){
                let sum = 0
                money.map((item)=>{
                    let amount = (typeof item.amount !== 'undefined')?item.amount:0
                    sum = sum + amount
                })
                return sum
            }
        },
        calInsurance(){
            this.insuranceAmount =  this.accounts.insuranceCal  
        },
        setCalBalanceRound(){
            let array = []

            for (let index = 0; index < this.room.currentRound; index++) {
                let balance = 0
                if(index === 0){
                    balance = calBalanceLast(this.groupAllData.accounts[index],0)
                }else{

                    balance = calBalanceLast(this.groupAllData.accounts[index],array[index-1])
                }
                
                array.push(balance) 
            
            }

            this.balanceRound = array
            var sumEventMoney = sumEventMoney
            function calBalanceLast(data,begin){
                let increase = data.income + data.tradeAccountReceivables + data.credit
                let decrease = data.cost + data.principle + data.interest + data.insurance +
                                data.economicInsights + data.brandingAmount + data.rdAmount + data.warehouseAmount + sumEventMoney(data.busniessEvenCard)
                return  (begin + increase) - decrease
            }

            function sumEventMoney(money){
                let sum = 0
                money.map((item)=>{
                    let amount = (typeof item.amount !== 'undefined')?item.amount:0
                    sum = sum + amount
                })
                return sum
            }
        },
    },
}
</script>

<style scoped>
  .creditBox{
    min-width: 400px;
    position: relative;
  }
  .creditDes{
    position: absolute;
    font-size: 14px;
    right: 0;
  }
</style>