<template>
    <div>
      <el-card class="box-card">
        <div slot="header" class="clearfix">
          <span class="font-weight-bold font-26">ตัดสินใจลงทุนเพิ่ม</span>
        </div>
        <el-row type="flex" justify="center" class="pd-b-4">
          <el-col :span="12">
            <div class="is-flex js-between ai-center pd-x-4 ">
                <span>Economic Insights</span>
                <div>
                  <span @click="insightModal = true" v-if="accounts.economicInsights > 0" class="mg-r-3 font-14 text-underline-hover cr-pointer">อ่านข่าว</span><el-button  @click="buyProductName = 'economicInsights',confirmModal = true" type="primary" plain round :disabled="(balanceMoney < 5000 || accounts.economicInsights > 0)">฿ 5,000</el-button> 
                </div>
                
              </div>
          </el-col>
          <el-col :span="12">
              <div class="is-flex js-between ai-center pd-x-4">
                <span>Branding</span>
                <el-button @click="buyProductName = 'branding',confirmModal = true" type="primary" plain round :disabled="(balanceMoney < 20000 && accounts.takeBranding <= 0)">{{(accounts.takeBranding > 0)?'Free ('+accounts.takeBranding+' times)':'฿ 20,000'}}  / Random Chance</el-button>
              </div>
          </el-col>
        </el-row>
        <el-row type="flex" justify="center" class="pd-b-4">
          <el-col :span="12">
            <div class="is-flex js-between ai-center pd-x-4">
                <span>R&D</span>
                <el-button @click="buyProductName = 'rd',confirmModal = true" type="primary" plain round :disabled="(balanceMoney < 20000 && accounts.takeRd <= 0)">{{(accounts.takeRd > 0)?'Free ('+accounts.takeRd+' times)':'฿ 20,000'}} / Random Chance</el-button>
              </div>
          </el-col>
          <el-col :span="12">
              <div class="is-flex js-between ai-center pd-x-4">
                <span>Warehouse</span>
                <el-button @click="buyProductName = 'warehouse',confirmModal = true" type="primary" plain round :disabled="(balanceMoney < 50000)">฿ 50,000 / 200 Stock</el-button>
              </div>
          </el-col>
        </el-row>
        <div class="pd-x-4 pd-b-5">
            <div class="is-flex js-between ai-center pd-b-5">
                <div></div>
                <span class="font-16">
                    <span class="font-weight-bold">Balance:</span>  
                    <span :class="balanceMoney > 0? 'color-success' : 'color-danger'"> ฿{{balanceMoney | comma}}</span> |
                    <span class="font-16">
                        <span class="font-weight-bold" >Total Stock: </span> <span 
                        :class="totalInventory >= (maxInventory-(maxInventory*0.05)) ?'color-danger':''">{{totalInventory}} / {{maxInventory}}</span>
                    </span>
                </span>
            </div>
        </div>
        <el-dialog :visible.sync="confirmModal" width="30%">
            <div>
              <h3 class="text-center">ยืนยันการซื้อ {{buyProductName == 'rd' ? 'R&D Rate' : buyProductName == 'branding' ? 'Branding Rate' : buyProductName == 'economicInsights' ? 'Economic Insights' : buyProductName == 'warehouse' ? 'Warehouse' : buyProductName}}</h3>
            </div>
            <span slot="footer" class="dialog-footer">
              <el-button @click="confirmModal = false">Cancel</el-button>
              <el-button type="primary" @click="buyInvestment(buyProductName)">Confirm</el-button>
            </span>
        </el-dialog>
          <div>
            <el-table
                :data="investList"
                border
                show-summary
                :summary-method="getSummaries"
                empty-text='No Data'
                class="width-max">
                    <el-table-column
                        prop="product"
                        header-align="center"
                        label="Product list"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="price"
                        header-align="center"
                        label="Price (THB)"
                        align="right"
                        width="200"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="quantity"
                        header-align="center"
                        label="Quantity"
                        width="200"
                    >
                        <template slot-scope="scope">
                            <el-input-number  
                                v-model="scope.row.quantity"  
                                :min="0"  
                                :max="scope.row.balance+scope.row.quantity" 
                                 :disabled="((scope.row.id === 5 || scope.row.id === 6) && accounts.rd < 4 )"
                                @change="(currentValue, oldValue)=>checkStock(currentValue, oldValue,scope.row)" 
                                step-strictly 
                                size="small"  
                                class="width-max"></el-input-number>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="price"
                        header-align="center"
                        align="center"
                        label="Total Price (THB)"
                        width="300"
                    >
                        <template slot-scope="scope" >
                            <span>{{scope.row.price * checkUndefinedNumber(scope.row.quantity) | comma}}</span>
                        </template>
                    </el-table-column>
            </el-table>

            <div class="text-right pd-t-4">
              <el-row justify="end" type="flex" align="middle">
                <p class="mg-r-4" v-if="sendOrderTime != '' && status == 1">ซื้อผลิตภัณฑ์ล่าสุดเมื่อ {{sendOrderTime | timeTh}}</p> 
                  <el-button  @click="buyProduct"  type="primary" icon="el-icon-s-order" plain round :loading="loading">Buy product</el-button> 
              </el-row>
            </div>

            
            <div v-if="status == 1 && room.mode != 'single'" class="animated slideInDown banner-top">
                กรุณารอผู้ประกอบการท่านอื่นลงทุน (ระหว่างรอผู้ประกอบการสามารถลงทุนเพิ่มเติมได้ตลอดเวลา)
            </div>
          </div>
        </el-card>
          <el-divider></el-divider>
          <div class="text-right ">
            
              <el-row justify="end" type="flex">
                <el-col :span="4">
                  <el-button v-if="room.mode == 'single'" @click="worldModal = true" class="w-100" type="primary" round >Next</el-button> 
                </el-col>
              </el-row>
              <p class="">* หากไม่ต้องการลงทุนเพิ่มกดปุ่ม Next เพื่อทำการไปต่อ</p>
          </div>
          <el-dialog :visible.sync="worldModal" width="30%">
            <div>
              <h2 class="text-center">ยืนยันดำเนินการต่อ</h2>
              <h3 class="text-center">คุณไม่ต้องการลงทุนเพิ่มแล้วใช่หรือไม่ ?</h3>
            </div>
            <span slot="footer" class="dialog-footer">
              <el-button @click="worldModal = false">Cancel</el-button>
              <el-button type="primary" @click="NextPage()" :loading="loadingAnnouce">Confirm</el-button>
            </span>
          </el-dialog>
          <el-dialog :visible.sync="insightModal" width="40%">
            <div class="font-16">
              <h2 class="text-center mg-t-0 color-success">Economic Insights</h2>
              <p>1. {{insights[room.currentRound-1].i1}}</p>
              <p>2. {{insights[room.currentRound-1].i2}}</p>
              <p>3. {{insights[room.currentRound-1].i3}}</p>
              <p>4. {{insights[room.currentRound-1].i4}}</p>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import {alertFailError,alertCatchError} from '@/service/alertMsg';
import {HTTP} from'@/service/axios'
import {checkUndefinedNumber} from '@/service/checkTypeValue';

export default {
    props:{
        activeTab:{
            type:String,
            default(){
                return ''
            }
        }
    },
    computed: {
      user(){
          return this.$store.state.user
      },
      room(){
          return this.$store.state.room
      },
      groupAllData(){
          return this.$store.state.groupAllData
      },
      accounts(){
          if(this.groupAllData === null){ return '' }
          let filter = this.groupAllData.accounts.filter(filter => filter.round === this.room.currentRound)
          if(filter.length > 0){
              return filter[0]
          }else{
              return ''
          }
      },
      accountsBegin(){
          if(this.groupAllData === null){ return '' }
          let filter = this.groupAllData.accounts.filter(filter => filter.round === (this.room.currentRound - 1))
          if(filter.length > 0){
              return filter[0]
          }else{
              return ''
          }
      }, 
      maxInventory(){
       return  this.accounts.warehouse + 200
      },
      priceProduct(){
        return this.$store.state.priceProduct
      }
    },
    watch: {
      async activeTab(){
        if(this.activeTab === 'invest'){
          this.$socket.client.emit('investment',{groupId:this.user._id,round:this.room.currentRound})
            await this.fetchAllData()
            await this.fetchPriceInventory()
            this.setInvestList()
            this.setBalanceMoney()
            this.setTotalInventory()
        }else if(this.activeTab !== 'invest'){
          this.insightModal = false
        }
      }
    },
    data() {
      return {
        insightModal:false,
        loadingAnnouce:false,
        worldModal:false,
        status:0,
        sendOrderTime:'',
        confirmModal:false,
        buyProductName:'',
        investList:[],
        balanceMoney:0,
        loading:false,
        balanceRound:0,
        totalInventory:0,
        dataBegin:[],
        insights:[
          {
            i1:'นักวิเคราะห์คาดการณ์ว่าภาวะกํารลงทุนในประเทศ Amfreland และ Belovia จะขยายตัวประมาณ 5%และ 10% ตามลำดับ เนื่องจากนโยบายรัฐที่ดึงดูดนักลงทุนต่างชาติ',
            i2:'จากการสำรวจพฤติกรรมผู้บริโภคในประเทศ Amfreland กลุ่มผู้บริโภคให้ความสำคัญกับ Branding มากกว่าราคา และนิยมสินค้าแปรรูปเพราะเข้ํากับวิถีชีวิตคนเมืองที่ยุ่งเหยิงจนไม่มีเวลาทำอาหารเอง',
            i3:'กำลังซื้อและสัดส่วนประชากรที่อยู่ในเมืองมีผลต่อการตัดสินใจซื้อข้าวในแต่ละประเภท โดยคนเมืองนิยมข้าวนึ่งกระป๋อง ในขณะที่คนรายได้น้อยจะเลือกบริโภคข้าวสารขาว',
            i4:'ผู้เชี่ยวชาญกระทรวงเกษตรและสหกรณ์ออกมาเปิดเผยว่า ในปีหน้า ผลผลิตทางการเกษตร  โดยเฉพาะข้าวจะมีมากกว่าเดิม เพราะปริมาณน้ำมีเพียงพอให้ทำการเกษตร'
          },
          {
            i1:'นักวิเคราะห์คาดการณ์ว่าเศรษฐกิจโลกยังขยายตัวต่อเนื่อง และการค้าการลงทุนระหว่างประเทศน่าจะขยายตัวอยู่ประมาณ 10%-15%',
            i2:'มีแหล่งข่าววงในภายในประเทศ เปิดเผยว่าคณะกรรมการนโยบายการเงิน (กนง.) จะทำการขึ้นอัตรดอกเบี้ยเชิงนโยบายเพื่อดึงดูดเงินทุนจากต่างประเทศให้มากขึ้น',
            i3:'รัฐบาลกระตุ้นการลงทุนภายในประเทศด้วยการเดินหน้าโครงการพัฒนาเขตเศรษฐกิจพิเศษ โดยให้สิทธิยกเว้นภาษีกับธุรกิจต่างชาติที่เข้าลงทุนในเขตพื้นที่ดังกล่าวเป็นเวลา 10 ปี',
            i4:'จำนวนประชากรของประเทศ Belovia มีแนวโน้มเพิ่มขึ้นโดยเฉลี่ย 15% ต่อปีในช่วง 3 ปีข้างหน้า'
          },
          {
            i1:'นักเศรษฐศาสตร์คาดการณ์ว่าเศรษฐกิจในประเทศ Amfreland จะชะลอการขยายตัวลง สืบเนื่องจากรัฐบาลประกาศใช้มาตรการการคลังแบบเกินดุล เพื่อควบคุมไม่ให้เกิดภาวะฟองสบู่ในประเทศ',
            i2:'ธนาคารโลกวิเคราะห์ว่ําประเทศ Belovia จะเป็นหนึ่งใน 5 อันดับแรกของประเทศที่มีอัตราการขยายตัวทางเศรษฐกิจสูงที่สุดโลก แม้ว่าจะมีปัจจัยเสี่ยงในเรื่องเสถียรภาพทางการเมือง',
            i3:'อัตราการขยายตัวของภาคอุตสาหกรรมภายในประเทศ และการขยายตัวของเขตเมืองของประเทศ Belovia มีแนวโน้มเพิ่มขึ้นอย่างต่อเนื่อง โดยคาดกันว่าจะโตเฉลี่ยอยู่ที่ 20%-30%',
            i4:'โครงการพัฒนาเขตเศรษฐกิจพิเศษได้รับกระแสตอบรับเป็นอย่างดีจากนักลงทุนต่างประเทศ โดยรัฐบาลได้บรรลุข้อตกลงในการร่วมลงทุนสร้างปัจจัยพื้นฐานกับกลุ่มทุนของประเทศ Amfreeland'
          },
          {
            i1:'แม้ว่ารัฐบาลประกาศใช้มาตรการการคลังแบบเกินดุล เพื่อควบคุมไม่ให้เกิดภาวะฟองสบู่ในประเทศ แต่ก็ยังไม่สามารถแก้ปัญหาภาวะฟองสบู่ในประเทศ Amfreland ได้ ซึ่งภาคอุตสาหกรรมหลักภายในประเทศ และอสังหาริมทรัพย์ได้รับผลกระทบอย่างหนัก',
            i2:'การบริโภคโดยเน้นกินโปรตีนและไขมันให้มาก และกินแป้งและน้ำตาลให้น้อยเป็นเทรนด์ที่นิยมในประเทศ Amfreland เพราะคนในประเทศตื่นตัวเรื่องการรักษาสุขภาพกันมากขึ้น',
            i3:'นักวิเคราะห์มั่นใจว่าภัยธรรมชาติที่เกิดขึ้นในประเทศ Belovia มีผลกระทบเชิงลบต่อผลผลิตทางการเกษตร ซึ่งจะส่งผลให้ต้องนําเข้ําสินค้าเกษตรและอาหารหลายรายการเพื่อตอบสนองตลาดผู้บริโภคที่มีประชากรขยายตัวอย่างรวดเร็ว',
            i4:'ผลิตภัณฑ์น้ำมันรำข้าวสกัดมีแนวโน้มขยายตัวอย่างมากและต่อเนื่อง ถึงแม้ว่าภาวะการขยายตัวทางเศรษฐกิจโลกจะเริ่มชะลอตัวลง'
          },
          {
            i1:'มีข่าววงในแจ้งว่ารัฐบาลประเทศ Amfreland จะประกาศยกเลิกสิทธิพิเศษทางภาษีศุลกากรกับสินค้าเกษตรที่นำเข้ามาเพื่อกระตุ้นภาคการเกษตรภายในประเทศ',
            i2:'ภาวะเศรษฐกิจในประเทศ Amfreland ค่อนข้างซบเซาทำให้เงินลงทุนไหลออกนอกประเทศ ซึ่งส่งผลดีต่อการขยายตัวในภาคอุตสาหกรรมของประเทศ Belovia',
            i3:'นักการตลาดชี้ว่าผู้บริโภคในประเทศ Belovia มีพฤติกรรมการบริโภคที่เปลี่ยนแปลงไป ส่วนหนึ่งเกิดจากการขยายตัวอย่ํางรวดเร็วของเมืองหลักและเมืองรองที่มีนิคมอุตสําหกรรม ทำให้มีการบริโภคอาหารแปรรูปมากขึ้น และมีเวลาประกอบอาหารเองลดลง',
            i4:'ผู้บริโภคที่เป็นชนชั้นแรงงานในประเทศ Belovia หันไปบริโภคข้าวที่ปลูกในประเทศมากขึ้นเพราะผลผลิตในปีนี้มีมากจึงมีราคาถูก แม้คุณภาพจะไม่ดีก็ตาม'
          }
        ]
      }
    },
    async mounted() {
      this.loading = true
      await this.fetchPriceInventory()
      await this.fetchAllData()
      this.setInvestList()
      this.onInvest()
    },
    methods: {
      NextPage(){
        this.loadingAnnouce = true
        HTTP.get('/bot/invest/'+this.room._id+'/'+this.room.currentRound).then(async (res)=>{
    
          if(res.data.success){
            this.worldModal = false
            this.room.current = "World's demand";
            await this.$store.dispatch("updateCurrent", this.room);
            await this.$socket.client.emit("getRoom", {
              roomNumber: this.room.roomNumber
            });
          }else{
            this.alertFailError()
          }
        }).catch(e=>{
            this.alertCatchError()
            console.log("NextPage error",e)
        })
        
      },
      onInvest(){
        this.$socket.client.on('resInvestment',async (res)=>{
      
          if(this.user._id == res.groupId){
            this.status = res.status
          }   
        })
      },
      fetchPriceInventory(){
        let obj ={
          round:this.room.currentRound
        }
        this.$store.dispatch('FETCH_PRICE_PRODUCT',obj)
      },
      async fetchAllData(){
        let obj ={
            roomId:this.room._id,
            groupId:this.user._id,
        }
        await this.$store.dispatch('FETCH_GROUP_ALL_DATA',obj)
        this.setCalBalanceRound()
        this.setBalanceMoney()
        this.setTotalInventory()
        this.loading = false
      },
      setInvestList(wr,jr,wrc,jrc,rbo,red){
        let invest = [
          {
              id:1,
              product: 'ข้าวสารขาว',
              price: this.priceProduct.whiteRice,
              quantity: (typeof wr !== 'undefined')?wr:0,
              balance:Infinity,
          },
          {
              id:2,
              product: 'ข้าวสารหอม',
              price: this.priceProduct.jasmineRice,
              quantity: (typeof jr !== 'undefined')?jr:0,
              balance:Infinity,
          },
          {
              id:3,
              product: 'ข้าวขาวนึ่งกระป๋อง',
              price: this.priceProduct.whiteRiceCan,
              quantity: (typeof wrc !== 'undefined')?wrc:0,
              balance:Infinity,
          },
          {
              id:4,
              product: 'ข้าวหอมนึ่งกระป๋อง',
              price: this.priceProduct.jasmineRiceCan,
              quantity: (typeof jrc !== 'undefined')?jrc:0,
              balance:Infinity,
          },
          {
              id:5,
              product: 'น้ำมันรำข้าวสกัดบริสุทธิ์',
              price: this.priceProduct.riceBranOil,
              quantity: (typeof rbo !== 'undefined')?rbo:0,
              balance:Infinity,
          },
          {
              id:6,
              product: 'เครื่องดื่มข้าวชูกำลัง',
              price: this.priceProduct.riceEnergyDrink,
              quantity: (typeof red !== 'undefined')?red:0,
              balance:Infinity,
          },
        ]
        this.investList = invest
        if(typeof wr === 'undefined'){
          this.dataBegin = []
        }
      },
      setBalanceMoney(status){
        let increase =  this.accounts.income + this.accounts.tradeAccountReceivables + this.accounts.credit 
        let decrease =  this.accounts.cost + this.calInvProductAmount() +  this.accounts.principle + this.accounts.interest + this.accounts.insurance +
                        this.accounts.economicInsights + this.accounts.brandingAmount + this.accounts.rdAmount + this.accounts.warehouseAmount + sumEventMoney(this.accounts.busniessEvenCard) 

        let begin = (this.room.currentRound !== 1)?this.balanceRound[this.room.currentRound-2]:0
        this.balanceMoney = (begin + increase) - decrease

        function sumEventMoney(money){
            let sum = 0
            money.map((item)=>{
                let amount = (typeof item.amount !== 'undefined')?item.amount:0
                sum = sum + amount
            })
            return sum
        }
      },
      setTotalInventory(){
          let quantity = 0
          this.investList.map((item)=>{
              quantity = quantity + item.quantity
          })
          let balance = this.groupAllData.inventory.whiteRice + this.groupAllData.inventory.jasmineRice + this.groupAllData.inventory.whiteRiceCan + this.groupAllData.inventory.jasmineRiceCan +
          this.groupAllData.inventory.riceBranOil + this.groupAllData.inventory.riceEnergyDrink 

          this.totalInventory = quantity + balance
      },
      calInvProductAmount(){
        let investAmoutn = 0
        this.investList.map((item)=>{
            investAmoutn = investAmoutn + (item.price * item.quantity)
        })

        return investAmoutn
      },
      setCalBalanceRound(){
        let array = []
        let history = []
        for (let index = 0; index < this.room.currentRound; index++) {
          let balance = 0
          if(index === 0){
            balance = calBalanceLast(this.groupAllData.accounts[index],0)
          }else{

            balance = calBalanceLast(this.groupAllData.accounts[index],array[index-1])
          }
          
          array.push(balance) 
          
        }

        this.balanceRound = array
        var sumEventMoney = sumEventMoney
        function calBalanceLast(data,begin){
          let increase = data.income + data.tradeAccountReceivables + data.credit
          let decrease = data.cost + data.principle + data.interest + data.insurance +
                        data.economicInsights + data.brandingAmount + data.rdAmount + data.warehouseAmount + sumEventMoney(data.busniessEvenCard)
          return  (begin + increase) - decrease
        }

        function sumEventMoney(money){
          let sum = 0
          money.map((item)=>{
              let amount = (typeof item.amount !== 'undefined')?item.amount:0
              sum = sum + amount
          })
          return sum
        }
        
      },
      buyProduct(){
        this.loading = true
        this.sendOrderTime = new Date()
        let pay = 0
        this.investList.map((item)=>{
          pay = pay + (item.quantity * item.price)
        })

        let obj ={
          roomId:this.room._id,
          groupId:this.user._id,
          round:this.room.currentRound,
          whiteRice:this.checkUndefinedNumber(this.investList[0].quantity),
          jasmineRice:this.checkUndefinedNumber(this.investList[1].quantity),
          whiteRiceCan:this.checkUndefinedNumber(this.investList[2].quantity),
          jasmineRiceCan:this.checkUndefinedNumber(this.investList[3].quantity),
          riceBranOil:this.checkUndefinedNumber(this.investList[4].quantity),
          riceEnergyDrink:this.checkUndefinedNumber(this.investList[5].quantity),
          toPay:pay
        }

        HTTP.post(`/investment/inventory/`,obj)
        .then(res=>{
            if(res.data.success){
                this.setInvestList()
                this.fetchAllData()
                this.$socket.client.emit('investment',{groupId:this.user._id,round:this.room.currentRound})
                this.$socket.client.emit('waitingInvestment',{roomId :this.room._id,round:this.room.currentRound})
                setTimeout(()=>{
                    this.$message({
                        message: 'Buy product success',
                        type: 'success'
                    });
                },500)     
            }else{
                this.alertFailError()
            }
        })
        .catch((e)=>{
            this.alertCatchError()
            console.log("sentToSo error",e)
        })
        .finally(()=>{
            setTimeout(()=>{
                this.loading = false
            },500)
              
        })
      },
      buyInvestment(type){

        this.loading = true
        let obj ={}

        if(type === 'economicInsights'){
          obj ={
            economicInsights:5000,
          }
        }else if(type === 'branding'){
          let value = [-0.1,0.05,0.05,0.10,0.10,0.15,0.15,0.20,0.20,0.25,0.25,0.25,0.30,0.30,0.35,0.35,0.40,0.40,0.45,0.45];
          let random = value[Math.floor(value.length * Math.random())] 
          this.$message({
              message: `Branding rate${(random > 0)?' +'+random:' '+random}`,
              type: (random > 0)?'success':'error'
          });

          let sum =  this.accounts.branding + random
 
          if(this.accounts.takeBranding > 0){
            obj ={
              branding:(sum <= 0)?1:sum,
              takeBranding:this.accounts.takeBranding - 1
            }
          }else{
            obj ={
              branding:(sum <= 0)?1:sum,
              brandingAmount:this.accounts.brandingAmount+20000
            }
          }
        }else if(type === 'rd'){
          let value = [0,0,0.5,0.5,0.5,0.5,0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5,1,1,1,1,1,1];
          let random = value[Math.floor(value.length * Math.random())] 
          this.$message({
              message: `R&D rate${(random > 0)?' +'+random:' '+random}`,
              type: (random > 0)?'success':'error'
          });

          let sum = this.accounts.rd + random
          if(this.accounts.takeRd > 0){
            obj ={
              rd:(sum < 0)?0:sum,
              takeRd:this.accounts.takeRd - 1
            }
          }else{
            obj ={
              rd:(sum < 0)?0:sum,
              rdAmount:this.accounts.rdAmount+20000
            }
          }
         
        }else{
          let sum = this.accounts.warehouse + 200
          obj ={
            warehouse:sum,
            warehouseAmount:this.accounts.warehouseAmount+50000
          }
        }

        HTTP.put(`investment/${this.room._id}/${this.user._id}/${this.room.currentRound}`,obj)
        .then(res=>{
            if(res.data.success){
                this.$socket.client.emit('investment',{groupId:this.user._id,round:this.room.currentRound})
                this.$socket.client.emit('waitingInvestment',{roomId :this.room._id,round:this.room.currentRound})
                if(type === 'branding'){
                  this.$store.commit('SET_RENDER_ANIMATE',{key:'bradnding',value:false})
                }else if(type === 'rd'){
                  this.$store.commit('SET_RENDER_ANIMATE',{key:'rd',value:false})
                }

                if(type === 'economicInsights'){
                  this.insightModal = true
                }

                setTimeout(()=>{
                  this.$store.commit('SET_RENDER_ANIMATE',{key:'bradnding',value:true})
                  this.$store.commit('SET_RENDER_ANIMATE',{key:'rd',value:true})
                },500)

                this.fetchAllData().then(()=>{
                  this.setInvestList(
                    this.investList[0].quantity,
                    this.investList[1].quantity,
                    this.investList[2].quantity,
                    this.investList[3].quantity,
                    this.investList[4].quantity,
                    this.investList[5].quantity
                  )
                })    
            }else{
                this.alertFailError()
            }
        })
        .catch((e)=>{
            this.alertCatchError()
            console.log("buyInvestment error",e)
        })
        .finally(()=>{
          this.confirmModal = false
            setTimeout(()=>{
                this.loading = false
            },500)
              
        })
      },
      async checkStock(currentValue, oldValue,model){
          let balance = 0
          let calMaxQ = 0
          if(typeof currentValue !== 'undefined'){
              let filter =  await this.dataBegin.filter(filter => filter.id === model.id)
              let totalProduct = this.totalInventory
              let totalmoney = this.balanceMoney
              if(filter.length > 0 && filter[0].id === model.id){
                  totalProduct = totalProduct - filter[0].quantity
                  totalmoney = (filter[0].quantity  * filter[0].price) + this.balanceMoney
                  let  delArray =  await this.dataBegin.filter(filter => filter.id !== model.id)
                  this.dataBegin = delArray
              }
              let stock = this.maxInventory - totalProduct
              calMaxQ = (Math.floor(totalmoney / model.price) > 0)?Math.floor(totalmoney / model.price):0
        
              if(currentValue >= stock ){
                  if(calMaxQ <= stock){
                      model.quantity = calMaxQ
                      model.balance = 0
                      balance = 0
                      this.setBalance(null,this.balanceMoney,'money',model)
                  }else{
                      model.quantity = stock
                      model.balance = 0
                      balance = 0
                      this.setBalance(balance,0,'stock',model)
                  }
              }else{
                  if(calMaxQ <= currentValue){
                      model.quantity = calMaxQ
                      model.balance = 0
                      balance = 0
                      this.setBalance(null,this.balanceMoney,'money',model)
                  }else{
                      model.balance = this.maxInventory - (totalProduct + currentValue)
                      balance = this.maxInventory - (totalProduct + currentValue)
                      this.setBalance(balance,null,'stock',model)
                  }
              }
              this.dataBegin.push(JSON.parse(JSON.stringify(model)))
          }else{ 
              model.quantity = 0
              let balance =  oldValue + model.balance 
              model.balance = balance
              this.setBalance(balance,null,'stock',model)
          } 
        },  
        async setBalance(balance,balanceMoney,status,model){
            await this.setBalanceMoney()
            await this.setTotalInventory()

            if(status === 'stock'){
                this.investList.map((item)=>{
                    if(item.id !== model.id){
                        item.balance = balance
                    }
                })
            }else{
                this.investList.map((item)=>{
                    if(item.id !== model.id){
                        let cal = Math.floor(balanceMoney / item.price)
                        item.balance = cal
                    }
                })
            }

        },  
        getSummaries(param) {
            const { columns, data } = param;
            const sums = [];
            columns.forEach((column, index) => {
            if (index === 0) {
                sums[index] = 'รวม';
                return;
            } 
                let sum = 0
                data.map((item)=>{
                sum = sum + (item.price * item.quantity)
                })
            

                sums[index] = (index === 3)?this.$options.filters.comma(sum):''
            });

            return sums;
        },
    },
}
</script>

<style scoped>

</style>