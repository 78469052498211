

import Vue from 'vue'

Vue.mixin({
    methods: {
        checkUndefinedNumber(value) {
            if(typeof value !== 'undefined'){
                return value
            }else{
                return 0
            }
        }
    },
  })
  